import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import "./OurDay.Module.css"

class OurDay extends React.Component {
  render() {
    return (
      <section id="our-day">
        <div className="our-day-flexbox">
          <div className="our-day-flowers">
            <StaticQuery
              query={graphql`
                query {
                  file(relativePath: { eq: "our-day-flowers.png" }) {
                    childImageSharp {
                      fluid(maxWidth: 300) {
                        ...GatsbyImageSharpFluid_tracedSVG
                      }
                    }
                  }
                }
              `}
              render={data => <Img fluid={data.file.childImageSharp.fluid} />}
            />
          </div>
          <div className="our-day-pull-right">
            <h1>Our Day</h1>
            <p>We can’t wait to see you on our wedding day!</p>
            <h2>Dress code</h2>
            <p className="italic">
              <a
                href="https://www.pinterest.co.uk/fudgewesby/summer-formal/"
                target="summerformal"
              >
                Summer formal
              </a>
            </p>
            <p>
              There is uneven ground and grass at both the church and evening
              venue so please bear this in mind when choosing footwear.
            </p>
            <h2>What to bring</h2>
            <p className="italic">
              We’d love it if you could please bring a coconut with you to the
              reception!
            </p>
            <p>
              Our evening reception will also be under canvas so bring warm
              clothing and wet weather gear if needed.
            </p>
            <h2>Friday night</h2>
            <p>
              You are very welcome to join us for a drink on Friday night. We
              will be in the bar at the Grosvenor Hotel in Stockbridge around 8
              o’clock.
            </p>
          </div>
        </div>
        <hr />
        <div className="timeline">
          <div className="timeline-section">
            <h2>1 o’clock</h2>
            <ul>
              <li>
                <h3>The Ceremony</h3>
                <address>
                  <a
                    href="https://goo.gl/maps/RW8k8zF442CTwuYE7"
                    title="Google Maps"
                    target="theChurch"
                  >
                    The Chapel at The Hospital of St.Cross, St.Cross Road,
                    Winchester, Hampshire, SO23 9SD
                  </a>
                </address>
                <p>
                  Please be seated 15 minutes prior to the ceremony time of 1
                  o’clock. Alex and the best men will be at the church from
                  12.30pm. The service will last around an hour.
                </p>
                <p>
                  There is plenty of parking on the field beside the church and
                  this will be signposted from the main road.
                </p>
              </li>
            </ul>
          </div>
          <div className="timeline-section">
            <h2>Followed by</h2>
            <ul>
              <li>
                <h3>The Reception</h3>
                <address>
                  <a
                    href="https://goo.gl/maps/LSr82yYs8Q6xNMXY7"
                    title="Google Maps"
                    target="theReception"
                  >
                    Houghton Lodge Gardens, North Houghton, Stockbridge SO20 6LQ
                  </a>
                </address>
                <p>
                  After the ceremony, please make your own way to the reception
                  venue, which is about 25 minutes by car. If you are staying
                  the night in Stockbridge, this is an ideal time to check into
                  your accommodation. Reception drinks will be served for an
                  hour before the wedding breakfast.
                </p>
                <p>
                  There will be allocated wedding parking signposted just beyond
                  the main entrance to Houghton Lodge Gardens.{" "}
                  <span class="strong">
                    You are welcome to leave your car overnight as long as it is
                    collected by 10.30am on Sunday.
                  </span>
                </p>
                <p class="strong">
                  If you have children, it’s important to know that there is a
                  river with no fencing near the marquee which determined and
                  unsupervised children may be tempted to explore.
                </p>
              </li>
            </ul>
          </div>
          <div className="timeline-section">
            <h2>12 midnight</h2>
            <ul>
              <li>
                <h3>Carriages &amp; the after party</h3>
                <address>
                  <a
                    href="https://goo.gl/maps/Vf7rGHqQ5t3UQR1i6"
                    title="Google Maps"
                    target="carriages"
                  >
                    The Greyhound on The Test, 31 High Street, Stockbridge, SO20
                    6EY
                  </a>
                </address>
                <p>
                  Our last dance will be just before midnight and after this we
                  will be inviting guests to The Greyhound on The Test to
                  continue partying until 2am.
                </p>
                <p>
                  There will be a shuttle bus service to The Greyhound on The
                  Test in Stockbridge from around 11.30pm. If you book to stay
                  in Stockbridge you will be able to walk to your accommodation
                  from there even if you’re not able to keep dancing! Please let
                  us know in your RSVP if you would like to reserve a seat.
                </p>
              </li>
            </ul>
          </div>
          <div className="timeline-section">
            <h2>Around 9 o'clock Sunday</h2>
            <ul>
              <li>
                <h3>Bacon butties &amp; Bloody Marys</h3>
                <address>
                  <a
                    href="https://goo.gl/maps/Vf7rGHqQ5t3UQR1i6"
                    title="Google Maps"
                    target="bacon"
                  >
                    The Greyhound on The Test, 31 High Street, Stockbridge, SO20
                    6EY
                  </a>
                </address>
                <p>
                  We’d love you to join us in the Courtyard of The Greyhound on
                  The Test before you head off on Sunday morning. For those who
                  have left cars at Houghton Lodge, feel free to come to The
                  Greyhound to carpool to collect cars by 10.30am.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default OurDay
